import { createGlobalStyle } from 'styled-components';

import { GTFlexa } from './fonts';

// Hyphens in Greek is not-allowed
interface GlobalStyleProps {
  locale?: string;
}

const dribeOrange = '#FF542B';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  head,
  ${GTFlexa.style}
  body {
    hyphens: ${props => (props?.locale === 'el' ? 'none' : 'auto')};
    margin: 0;
    padding: 0;
    
    ${GTFlexa.style}

    .grecaptcha-badge { visibility: hidden; }

    a {
      text-decoration: none;
      cursor: pointer;

      &:active,
      &:hover {
        outline: 0;
      }

      &:focus {
        outline: 0;
      }
    }

    b {
      font-family: GTFlexa-Bd, sans-serif;
      font-weight: inherit;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    dl,
    menu,
    ol {
      padding: inherit;
      list-style-type: decimal;
    },
    ul {
      padding: inherit;
      list-style-type: square;
    },
    li {
      &::marker {
        color: ${dribeOrange}
      }
    },
    p,
    pre {
      margin: 0;
    }
  }
`;

export default GlobalStyle;
