import React, { ReactNode } from 'react';

import { DribeThemeProvider } from '@dribe-io/ui-kit/providers/theme';
import { businessTheme, theme } from '@dribe-io/ui-kit/theme';

import { IsB2B } from 'types/response';

type ThemeProviderProps = {
  children: ReactNode;
} & IsB2B;

const ThemeProvider = ({ isB2B, children }: ThemeProviderProps) => {
  return (
    <DribeThemeProvider theme={isB2B ? businessTheme : theme}>
      {children}
    </DribeThemeProvider>
  );
};

export default ThemeProvider;
