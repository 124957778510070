import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
  HttpLink
} from '@apollo/client';
import merge from 'deepmerge';

import { createErrorLink, createSentryLink } from './apollo';

const SENTRY_DSN = process.env.SENTRY_DSN;
const GRAPHQL_LINK = `${process.env.NEXT_PUBLIC_API_HOST}/graphql`;

const createGraphqlLink = () => {
  const httpLink = new HttpLink({
    uri: GRAPHQL_LINK
  });

  return httpLink;
};

const createApolloClient = () => {
  const graphqlLink = createGraphqlLink();
  const errorLink = SENTRY_DSN ? createSentryLink() : createErrorLink();

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, graphqlLink]),
    headers: {
      'Endpoint-Version': '2'
    }
  });
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const getDribeApollo = (
  initialState: NormalizedCacheObject | null = null
) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};
