var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.0.8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import getConfig from 'next/config';

import * as Sentry from '@sentry/nextjs';

const PRODUCTION_ENVIRONMENT = process.env.NEXT_PUBLIC_PRODUCTION_ENVIRONMENT;
const SENTRY_DSN = process.env.SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
const SENTRY_LOG_LEVEL = (process.env.SENTRY_LOG_LEVEL ||
  'error') as Sentry.SeverityLevel;

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  enabled: !!SENTRY_DSN,
  release: publicRuntimeConfig.version,

  initialScope: {
    level: SENTRY_LOG_LEVEL
  },

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: PRODUCTION_ENVIRONMENT ? 0.1 : 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: PRODUCTION_ENVIRONMENT ? 0.1 : 1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true
    })
  ]
});
