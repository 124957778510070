import Router from 'next/router';

import { apiObject } from 'rudder-sdk-js';

import {
  EventSpec,
  PageEventSpec,
  TrackEventSpec
} from '@lib/rudderstack/EventSpecs';

const dataPlaneUrl = process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL;
const writeKey = process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY;
const marketId = process.env.NEXT_PUBLIC_MARKET_ID;

export const trackingEnabled = !!dataPlaneUrl && !!writeKey;

export const rudderInitialize = async () => {
  if (window.rudderanalytics?.isReady) return;
  if (trackingEnabled && !!dataPlaneUrl && !!writeKey) {
    window.requiresConsent = true;
    window.rudderInit = () => {
      window.rudderanalytics?.load(writeKey, dataPlaneUrl, {
        cookieConsentManager: {
          oneTrust: {
            enabled: true
          }
        },
        integrations: { All: true }
      });

      window.rudderanalytics?.ready(() => {
        if (window.rudderanalytics) {
          window.rudderanalytics.isReady = true;
          if (window.eventCache) {
            const eventArgs = {
              tag: 'cached'
            };
            for (const event of window.eventCache) {
              event(eventArgs);
            }
            window.eventCache = undefined;
          }
        }
      });
    };

    window.OptanonWrapper = () => {
      if (window.OneTrust?.IsAlertBoxClosed() && window.rudderInit) {
        const consentUpdated = window.rudderanalytics?.isReady;
        window.requiresConsent = false;
        window.rudderInit();

        if (consentUpdated) {
          Router.reload();
        }
      }
    };

    window.rudderanalytics = await import('rudder-sdk-js');
    if (window.OneTrust?.IsAlertBoxClosed() && window.OnetrustActiveGroups) {
      window.requiresConsent = false;
      window.rudderInit();
    }
  }
};

const addToEventCache = (func: (eventArgs?: apiObject) => void) => {
  if (window.eventCache) {
    window.eventCache.push(func);
  } else {
    window.eventCache = [func];
  }
};

const onReady = (
  func: (eventArgs?: apiObject) => void,
  fallback?: () => void
) => {
  if (window.rudderanalytics?.isReady && trackingEnabled) {
    func();
  } else if (trackingEnabled) {
    addToEventCache(func);
    const timer = setInterval(() => {
      if (window.rudderanalytics?.isReady) {
        func();
        clearInterval(timer);
      } else if (window.requiresConsent && fallback) {
        fallback();
        clearInterval(timer);
      }
    }, 500);
  }
};

export const trackEvent = <T extends EventSpec>(event: TrackEventSpec<T>) => {
  onReady(
    (eventArgs?: apiObject) => {
      const props = getProperties(event.spec, eventArgs);
      window.rudderanalytics?.track(event.name, props);
    },
    () => {
      postTrack(event.name, event.spec);
    }
  );
};

export const trackPage = <T extends EventSpec>(event: PageEventSpec<T>) => {
  onReady(
    (eventArgs?: apiObject) => {
      const props = getProperties(event?.spec, eventArgs);
      window.rudderanalytics?.page(event?.category, event.title, props);
    },
    () => {
      postPage(event.title, event?.spec);
    }
  );
};

const getProperties = (properties?: EventSpec, extras?: apiObject) => {
  const props = properties || {};
  props['market-id'] = marketId;
  return { ...props, ...extras };
};

const postTrack = (event: string, properties?: apiObject) => {
  return postRudder('/v1/track', {
    anonymousId: 'anon-id-web',
    event,
    properties
  });
};

const postPage = (page: string, properties?: apiObject) => {
  return postRudder('/v1/page', {
    anonymousId: 'anon-id-web',
    page,
    properties
  });
};

const postRudder = (path: string, payload: apiObject) => {
  return fetch(dataPlaneUrl + path, {
    method: 'POST',
    headers: getRudderHttpHeaders(),
    body: JSON.stringify(payload)
  });
};

const getRudderHttpHeaders = () => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${Buffer.from(writeKey + ':').toString('base64')}`
  };
  return headers;
};
