import { useEffect } from 'react';

import type { AppProps } from 'next/app';
import router, { useRouter } from 'next/router';

import { appWithTranslation } from 'next-i18next';

import { ApolloProvider } from '@apollo/client';
import { ReCaptchaProvider } from 'next-recaptcha-v3';

import ThemeProvider from '@components/ThemeProvider';
import { getDribeApollo } from '@lib/apollo/dribeApollo';
import { rudderInitialize, trackPage } from '@lib/rudderstack';
import GlobalStyle from '@theme/globalStyle';

const App = ({ Component, pageProps = {} }: AppProps) => {
  const { locale } = useRouter();
  const client = getDribeApollo();

  useEffect(() => {
    rudderInitialize();
    track();

    router.events.on('routeChangeComplete', () => {
      track();
    });
    return () => {
      router.events.off('routeChangeComplete', () => {
        undefined;
      });
    };
  }, []);

  const track = () => {
    // Timeout required to avoid document data from previous page
    setTimeout(() => {
      const title = document.title;
      const url = window.location.href;
      trackPage({
        title,
        spec: {
          title,
          url
        }
      });
    }, 1);
  };

  const isB2B = pageProps?.layout?.isB2B;

  return (
    <>
      <GlobalStyle locale={locale} />
      <ApolloProvider client={client}>
        <ThemeProvider isB2B={isB2B}>
          <ReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            useEnterprise
            useRecaptchaNet
            language={locale}
          >
            <Component {...pageProps} />
          </ReCaptchaProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default appWithTranslation(App);
